
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    origin: {
      type: String,
      required: true,
    },
    destination: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const store = useStore()
    const environment = computed(() => store.state.switchEnv)

    // Determine if the environment is production
    const isProduction = computed(() => environment.value === 'production')

    return {
      isProduction
    }
  }
})
